import React from 'react';
import { Typography, Card, Tabs, Divider, Space, Table } from 'antd';
import { CodeOutlined, FileOutlined, ApiOutlined } from '@ant-design/icons';
import Footer from '../components/Footer';

const { Title, Paragraph, Text, Link } = Typography;
const { TabPane } = Tabs;

const ApiDocs = () => {
  // API端点表格列定义
  const endpointColumns = [
    {
      title: '端点',
      dataIndex: 'endpoint',
      key: 'endpoint',
      render: (text) => <Text code>{text}</Text>,
    },
    {
      title: '方法',
      dataIndex: 'method',
      key: 'method',
      render: (text) => (
        <Text code style={{ color: text === 'GET' ? '#52c41a' : text === 'POST' ? '#1890ff' : text === 'DELETE' ? '#f5222d' : '#fa8c16' }}>
          {text}
        </Text>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '认证',
      dataIndex: 'auth',
      key: 'auth',
      render: (text) => (text ? '需要' : '不需要'),
    },
  ];

  // API端点数据
  const endpointData = [
    {
      key: '1',
      endpoint: '/api/v1/resumes/generate',
      method: 'POST',
      description: '根据提供的JSON Resume数据生成PDF格式简历，支持多种模板和自定义选项',
      auth: true,
    },
    {
      key: '2',
      endpoint: '/api/v1/templates',
      method: 'GET',
      description: '获取系统中所有可用的简历模板列表，包含模板ID、名称、描述和缩略图',
      auth: false,
    },
    {
      key: '3',
      endpoint: '/api/v1/resumes/file/:fileId',
      method: 'GET',
      description: '通过临时URL获取已生成的简历PDF文件，链接有效期 5 分钟',
      auth: false,
    },
    {
      key: '4',
      endpoint: '/api/v1/resumes/convert-text',
      method: 'POST',
      description: '将自然语言描述的简历文本或上传的简历文件（PDF、DOCX等）转换为结构化的JSON Resume格式',
      auth: true,
    },
    {
      key: '5',
      endpoint: '/api/v1/resumes/analyze',
      method: 'POST',
      description: '分析简历文本或上传的简历文件，提供评分、优缺点分析、改进建议和关键词匹配度',
      auth: true,
    },
  ];

  return (
    <>
      <div>
        <Title level={2}>API文档</Title>
        <Paragraph>
          NovaCV API提供了一套简单而强大的接口，用于生成和管理简历。以下是API的详细文档。
        </Paragraph>

        <Tabs defaultActiveKey="overview">
          <TabPane
            tab={
              <span>
                <ApiOutlined />
                概述
              </span>
            }
            key="overview"
          >
            <Card>
              <Title level={4}>API概述</Title>
              <Paragraph>
                NovaCV API是一个RESTful API，使用JSON格式进行数据交换。所有API请求都需要通过HTTPS进行。
              </Paragraph>

              <Title level={4}>基本URL</Title>
              <Paragraph>
                <Text code>https://api.nova-cv.com/api/v1</Text>
              </Paragraph>

              <Title level={4}>认证</Title>
              <Paragraph>
                大多数API端点需要认证。您可以使用API密钥进行认证。
              </Paragraph>
              <Paragraph>
                <Text strong>API密钥认证：</Text> 在请求头中添加 <Text code>Authorization</Text> 字段，格式为 <Text code>Bearer YOUR_API_KEY</Text>
              </Paragraph>

              <Title level={4}>响应格式</Title>
              <Paragraph>
                所有API响应都使用JSON格式，并包含以下字段：
              </Paragraph>
              <ul>
                <li><Text code>success</Text>: 布尔值，表示请求是否成功</li>
                <li><Text code>message</Text>: 字符串，表示响应消息（可选）</li>
                <li><Text code>data</Text>: 对象，包含响应数据（可选）</li>
                <li><Text code>error</Text>: 对象，包含错误信息（仅在请求失败时）</li>
              </ul>

              <Title level={4}>JSON Resume格式</Title>
              <Paragraph>
                NovaCV API使用标准的JSON Resume格式作为简历数据的结构。这是一个开放的标准，用于描述简历数据。
                以下是JSON Resume的主要结构：
              </Paragraph>
              <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', maxHeight: '400px', overflow: 'auto' }}>
                {`{
  "basics": {
    "name": "姓名",
    "label": "职位头衔",
    "image": "照片URL",
    "email": "电子邮件",
    "phone": "电话号码",
    "url": "个人网站",
    "summary": "个人简介",
    "location": {
      "address": "地址",
      "postalCode": "邮编",
      "city": "城市",
      "countryCode": "国家代码",
      "region": "地区"
    },
    "profiles": [
      {
        "network": "社交网络名称",
        "username": "用户名",
        "url": "社交网络链接"
      }
    ]
  },
  "work": [
    {
      "name": "公司名称",
      "position": "职位",
      "startDate": "开始日期 (YYYY-MM-DD)",
      "endDate": "结束日期 (YYYY-MM-DD)",
      "summary": "工作描述",
      "highlights": [
        "工作亮点1",
        "工作亮点2"
      ]
    }
  ],
  "education": [
    {
      "institution": "学校名称",
      "area": "专业领域",
      "studyType": "学位类型",
      "startDate": "开始日期 (YYYY-MM-DD)",
      "endDate": "结束日期 (YYYY-MM-DD)",
      "score": "成绩",
      "courses": [
        "课程1",
        "课程2"
      ]
    }
  ],
  "skills": [
    {
      "name": "技能名称",
      "level": "技能水平",
      "keywords": [
        "关键词1",
        "关键词2"
      ]
    }
  ],
  "languages": [
    {
      "language": "语言名称",
      "fluency": "熟练程度"
    }
  ],
  "projects": [
    {
      "name": "项目名称",
      "startDate": "开始日期 (YYYY-MM-DD)",
      "endDate": "结束日期 (YYYY-MM-DD)",
      "description": "项目描述",
      "highlights": [
        "项目亮点1",
        "项目亮点2"
      ]
    }
  ],
  "awards": [
    {
      "title": "奖项名称",
      "date": "获奖日期 (YYYY-MM-DD)",
      "awarder": "颁奖机构",
      "summary": "奖项描述"
    }
  ],
  "certificates": [
    {
      "name": "证书名称",
      "date": "获得日期 (YYYY-MM-DD)",
      "issuer": "颁发机构",
      "url": "证书链接"
    }
  ],
  "publications": [
    {
      "name": "出版物名称",
      "publisher": "出版商",
      "releaseDate": "发布日期 (YYYY-MM-DD)",
      "url": "出版物链接",
      "summary": "出版物描述"
    }
  ],
  "volunteer": [
    {
      "organization": "组织名称",
      "position": "职位",
      "url": "组织链接",
      "startDate": "开始日期 (YYYY-MM-DD)",
      "endDate": "结束日期 (YYYY-MM-DD)",
      "summary": "志愿者工作描述",
      "highlights": [
        "志愿者工作亮点1",
        "志愿者工作亮点2"
      ]
    }
  ],
  "interests": [
    {
      "name": "兴趣爱好",
      "keywords": [
        "关键词1",
        "关键词2"
      ]
    }
  ],
  "references": [
    {
      "name": "推荐人姓名",
      "reference": "推荐内容"
    }
  ]
}`}
              </pre>
              <Paragraph>
                <Text strong>注意：</Text> 并非所有字段都是必需的。您可以根据需要提供相关字段。不同的简历模板可能会使用不同的字段。
              </Paragraph>

              <Title level={4}>错误处理</Title>
              <Paragraph>
                当API请求失败时，响应将包含一个错误对象，其中包含错误代码和错误消息。
              </Paragraph>
              <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                {`{
  "success": false,
  "error": {
    "code": 401,
    "message": "未授权"
  }
}`}
              </pre>

              <Title level={4}>速率限制</Title>
              <Paragraph>
                为了防止滥用，API实施了速率限制。默认情况下，每个IP地址每15分钟最多可以发送100个请求。
              </Paragraph>
            </Card>
          </TabPane>

          <TabPane
            tab={
              <span>
                <FileOutlined />
                端点
              </span>
            }
            key="endpoints"
          >
            <Card>
              <Title level={4}>API端点</Title>
              <Paragraph>
                以下是所有可用的API端点：
              </Paragraph>

              <Table
                columns={endpointColumns}
                dataSource={endpointData}
                pagination={false}
              />

              <Divider />

              <Title level={4}>详细文档</Title>
              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div>
                  <Title level={5}>1. 生成简历PDF (POST /api/v1/resumes/generate)</Title>
                  <Paragraph>
                    <Text strong>描述：</Text> 根据提供的JSON Resume数据和模板生成PDF格式的简历。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>认证：</Text> 需要API密钥
                  </Paragraph>
                  <Paragraph>
                    <Text strong>请求参数：</Text>
                  </Paragraph>
                  <ul>
                    <li><Text code>resumeData</Text> (对象，必填): 符合JSON Resume标准的简历数据（参见上方的JSON Resume格式说明）</li>
                    <li><Text code>templateName</Text> (字符串，可选): 要使用的简历模板名称，默认为"elite"</li>
                    <li><Text code>options</Text> (对象，可选): 生成选项，如纸张大小、颜色等</li>
                  </ul>
                  <Paragraph>
                    <Text strong>响应：</Text>
                  </Paragraph>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`{
  "success": true,
  "data": {
    "fileUrl": "https://api.nova-cv.com/api/v1/resumes/file/abc123",
    "expiresAt": "2023-06-01T12:00:00Z",
    "thumbnailUrl": "https://api.nova-cv.com/api/v1/resumes/thumbnail/abc123"
  }
}`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>2. 获取所有可用简历模板 (GET /api/v1/templates)</Title>
                  <Paragraph>
                    <Text strong>描述：</Text> 获取系统中所有可用的简历模板列表。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>认证：</Text> 不需要
                  </Paragraph>
                  <Paragraph>
                    <Text strong>请求参数：</Text> 无
                  </Paragraph>
                  <Paragraph>
                    <Text strong>响应：</Text>
                  </Paragraph>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`{
  "success": true,
  "templates": [
    {
      "id": "modern",
      "name": "现代简约",
      "description": "简洁现代的设计风格",
      "thumbnail": "https://api.example.com/thumbnails/modern.png"
    },
    {
      "id": "professional",
      "name": "专业商务",
      "description": "正式专业的商务风格",
      "thumbnail": "https://api.example.com/thumbnails/professional.png"
    },
    {
      "id": "creative",
      "name": "创意设计",
      "description": "适合创意行业的独特设计",
      "thumbnail": "https://api.example.com/thumbnails/creative.png"
    }
  ]
}`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>3. 通过临时URL获取简历PDF (GET /api/v1/resumes/file/:fileId)</Title>
                  <Paragraph>
                    <Text strong>描述：</Text> 通过临时URL获取生成的简历PDF文件。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>认证：</Text> 不需要
                  </Paragraph>
                  <Paragraph>
                    <Text strong>路径参数：</Text>
                  </Paragraph>
                  <ul>
                    <li><Text code>fileId</Text> (字符串，必填): 简历文件的唯一标识符</li>
                  </ul>
                  <Paragraph>
                    <Text strong>响应：</Text> PDF文件流，Content-Type: application/pdf
                  </Paragraph>
                  <Paragraph>
                    <Text strong>错误响应：</Text>
                  </Paragraph>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`{
  "success": false,
  "message": "链接无效或已过期"
}`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>4. 将自然语言描述转换为JSON Resume格式 (POST /api/v1/resumes/convert-text)</Title>
                  <Paragraph>
                    <Text strong>描述：</Text> 将自然语言描述的简历文本或上传的简历文件转换为结构化的JSON Resume格式。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>认证：</Text> 需要API密钥
                  </Paragraph>
                  <Paragraph>
                    <Text strong>请求参数：</Text>
                  </Paragraph>
                  <ul>
                    <li><Text code>resumeText</Text> (字符串，可选): 自然语言描述的简历文本</li>
                    <li><Text code>file</Text> (文件，可选): 上传的简历文件（支持PDF、DOCX、TXT等格式）</li>
                  </ul>
                  <Paragraph>
                    <Text strong>注意：</Text> 必须提供 <Text code>resumeText</Text> 或 <Text code>file</Text> 其中之一。如果同时提供，将优先处理文件。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>响应：</Text> 返回符合JSON Resume标准的简历数据（参见上方的JSON Resume格式说明）
                  </Paragraph>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`{
  "success": true,
  "data": {
    "jsonResume": {
      "basics": {
        "name": "John Doe",
        "label": "Software Engineer",
        "email": "john@example.com",
        "phone": "(123) 456-7890",
        "summary": "Experienced software engineer with a passion for building scalable applications."
      },
      "work": [
        {
          "company": "Example Corp",
          "position": "Senior Developer",
          "startDate": "2018-01-01",
          "endDate": "2021-01-01",
          "summary": "Led development of cloud-based applications."
        }
      ],
      "education": [
        {
          "institution": "University of Example",
          "area": "Computer Science",
          "studyType": "Bachelor",
          "startDate": "2014-01-01",
          "endDate": "2018-01-01"
        }
      ]
    }
  }
}`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>5. 分析简历内容 (POST /api/v1/resumes/analyze)</Title>
                  <Paragraph>
                    <Text strong>描述：</Text> 分析简历文本或上传的简历文件，提供改进建议和评分。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>认证：</Text> 需要API密钥
                  </Paragraph>
                  <Paragraph>
                    <Text strong>请求参数：</Text>
                  </Paragraph>
                  <ul>
                    <li><Text code>resumeText</Text> (字符串，可选): 要分析的简历文本内容</li>
                    <li><Text code>file</Text> (文件，可选): 上传的简历文件（支持PDF、DOCX、TXT等格式）</li>
                  </ul>
                  <Paragraph>
                    <Text strong>注意：</Text> 必须提供 <Text code>resumeText</Text> 或 <Text code>file</Text> 其中之一。如果同时提供，将优先处理文件。
                  </Paragraph>
                  <Paragraph>
                    <Text strong>响应：</Text>
                  </Paragraph>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`{
  "success": true,
  "data": {
    "score": 85,
    "strengths": [
      "清晰的工作经历描述",
      "技能与职位匹配度高"
    ],
    "weaknesses": [
      "缺少量化的成就描述",
      "教育经历部分可以更详细"
    ],
    "suggestions": [
      "添加具体的项目成果和数据",
      "突出领导能力和团队协作经验"
    ],
    "keywordAnalysis": {
      "matched": ["软件工程", "云应用", "开发"],
      "missing": ["敏捷开发", "项目管理"]
    }
  }
}`}
                  </pre>
                </div>
              </Space>
            </Card>
          </TabPane>

          <TabPane
            tab={
              <span>
                <CodeOutlined />
                示例
              </span>
            }
            key="examples"
          >
            <Card>
              <Title level={4}>API使用示例</Title>

              <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <div>
                  <Title level={5}>1. 生成简历</Title>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`curl -X POST https://api.nova-cv.com/api/v1/resumes/generate \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "resumeData": {
      "basics": {
        "name": "John Doe",
        "label": "Software Engineer",
        "email": "john@example.com",
        "phone": "(123) 456-7890",
        "summary": "Experienced software engineer with a passion for building scalable applications."
      },
      "work": [
        {
          "company": "Example Corp",
          "position": "Senior Developer",
          "startDate": "2018-01-01",
          "endDate": "2021-01-01",
          "summary": "Led development of cloud-based applications."
        }
      ],
      "education": [
        {
          "institution": "University of Example",
          "area": "Computer Science",
          "studyType": "Bachelor",
          "startDate": "2014-01-01",
          "endDate": "2018-01-01"
        }
      ]
    },
    "templateName": "modern",
    "options": {
      "paperSize": "a4",
      "colorAccent": "#2196f3"
    }
  }'`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>2. 获取简历模板</Title>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`curl -X GET https://api.nova-cv.com/api/v1/templates \\
  -H "Content-Type: application/json"`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>3. 分析简历内容</Title>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`# 使用文本分析
curl -X POST https://api.nova-cv.com/api/v1/resumes/analyze \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "resumeText": "John Doe\\nSoftware Engineer\\n\\nEXPERIENCE\\nExample Corp, Senior Developer (2018-2021)\\nLed development of cloud-based applications.\\n\\nEDUCATION\\nUniversity of Example, Bachelor of Computer Science (2014-2018)"
  }'`}
                  </pre>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', marginTop: '10px' }}>
                    {`# 使用文件上传分析
curl -X POST https://api.nova-cv.com/api/v1/resumes/analyze \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -F "file=@/path/to/resume.pdf"`}
                  </pre>
                </div>

                <div>
                  <Title level={5}>4. 将简历文本转换为JSON格式</Title>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                    {`# 使用文本转换
curl -X POST https://api.nova-cv.com/api/v1/resumes/convert-text \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{
    "resumeText": "John Doe\\nSoftware Engineer\\n\\nEXPERIENCE\\nExample Corp, Senior Developer (2018-2021)\\nLed development of cloud-based applications.\\n\\nEDUCATION\\nUniversity of Example, Bachelor of Computer Science (2014-2018)"
  }'`}
                  </pre>
                  <pre style={{ background: '#f5f5f5', padding: '10px', borderRadius: '4px', marginTop: '10px' }}>
                    {`# 使用文件上传转换
curl -X POST https://api.nova-cv.com/api/v1/resumes/convert-text \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -F "file=@/path/to/resume.pdf"`}
                  </pre>
                </div>
              </Space>
            </Card>
          </TabPane>
        </Tabs>
      </div>
      <Footer />
    </>
  );
};

export default ApiDocs; 